<template>
    <div class="plate-center">
        <img class="yh-mt20 yh-mb20" src="../assets/1.png" alt />
    </div>
</template>
<script>
export default {
    name: "exam",
    data() {
        return {
            pageName: "在线考试", // 当前页面名字
            fwb: "",
        };
    },
    created() {
        // this.getInfo();
    },
    methods: {
        //获取关于我们的数据
        getInfo() {
            this.$axios.get(this.$api.aboutUs).then((res) => {
                if (res.code == 1) {
                    this.fwb = this.parseRichImgStyle(res.data.value);
                }
            });
        },
    },
};
</script>



<style scoped lang="scss">
</style>
